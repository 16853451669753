export enum EventType {
  DISTILLERY_TOUR = 'tour',
  TASTING = 'tasting',
  BRUNCH = 'brunch',
  CUSTOM = 'custom',
}

interface BookingSlotData {
  [key: string]: string[];
}

export interface Booking {
  eventType: EventType;
  start?: number;
  end?: number;
  slots: BookingSlotData;
}
