import { Spinner } from '@shopify/polaris';
import classes from './Loading.module.css';

const Loading = () => {
  return (
    <div className={classes.container}>
      <Spinner accessibilityLabel="Loading app" size="large" />
    </div>
  );
};

export default Loading;
