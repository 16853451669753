import { AppProvider, Frame, Navigation } from '@shopify/polaris';
import { CalendarTickMajor, ProductsMajor } from '@shopify/polaris-icons';
import enTranslations from '@shopify/polaris/locales/en.json';
import { Link, useLocation } from 'react-router-dom';
import Error from './components/error/Error';
import Loading from './components/loading/Loading';
import AuthProvider from './context/AuthContext';
import useAuth from './hooks/useAuth';
import Routes from './routes';

function App() {
  const { token, shop, error } = useAuth();
  const location = useLocation();

  const navigation = (
    <Navigation location="/">
      <Navigation.Section
        fill
        items={[
          {
            url: '/',
            label: 'Bookings',
            icon: CalendarTickMajor,
            selected: location.pathname === '/',
          },
          {
            url: '/products',
            label: 'Products',
            icon: ProductsMajor,
            selected: location.pathname === '/products',
          },
          {
            url: '/neworder',
            label: 'New order',
            icon: ProductsMajor,
            selected: location.pathname === '/neworder',
          }
        ]}
      />
    </Navigation>
  );
  return (
    <AppProvider
      i18n={enTranslations}
      linkComponent={(props: any) => (
        <Link className={props.className} to={props.url || ''}>
          {props.children}
        </Link>
      )}
    >
      <AuthProvider token={token} shop={shop}>
        <Frame navigation={!token || error ? null : navigation}>
          {!token ? <Loading /> : error ? <Error /> : <Routes />}
        </Frame>
      </AuthProvider>
    </AppProvider>
  );
}

export default App;
