import { ReactElement, useState } from 'react';
import {
  Page,
  Card,
  IndexTable,
  DataTable,
  TextStyle,
  Badge,
  Modal,
  TextContainer,
  Button,
  Heading,
  EmptyState,
  Stack,
  TextField,
} from '@shopify/polaris';
import {
  ChevronRightMinor,
  ChevronLeftMinor,
  CalendarMajor,
  FilterMajor,
} from '@shopify/polaris-icons';
import { IndexTableHeading } from '@shopify/polaris/dist/types/latest/src/components/IndexTable';
import { NonEmptyArray } from '@shopify/polaris/dist/types/latest/src/types';
import { useToken } from '../context/AuthContext';
import useBookings from '../hooks/useBookings';
import { BookingEvent } from '../api/interfaces/booking-event';
import useDrawer, { Payment } from '../hooks/useDrawer';
import { markProcessed } from '../api';
import { EventType } from '../api/interfaces/exception';

const headings: NonEmptyArray<IndexTableHeading> = [
  { title: 'Event' },
  { title: 'Customer' },
  { title: 'Payment' },
  { title: 'Time' },
  { title: 'Processed' },
];

const resourceName = {
  singular: 'booking',
  plural: 'bookings',
};

const Bookings = () => {
  const token = useToken();
  const [date, setDate] = useState(() => {
    const d = new Date();
    d.setHours(0, 0, 0, 0);
    return d;
  });
  const { bookings, retreiveBookings, DatePickerModal, updateBooking } =
    useBookings(token, date);
  const [orderData, setOrderData] = useState<BookingEvent[]>([]);
  const [open, setOpen] = useState(false);
  const { Drawer, toggleDrawer, filters } = useDrawer();

  const getEventTag = (evt: string) => {
    const tags = {
      tour: <Badge status="critical">Tour</Badge>,
      tasting: <Badge status="warning">Tasting</Badge>
    } as { [key: string]: ReactElement };
    return tags[evt];
  };

  const handleRowClick = (...params: any) => {
    const identifier = params[2];

    const booking = bookings!.find((b) => b.identifier === identifier);
    if (booking) {
      const { orderId } = booking;
      const orderBookings = bookings!.filter((b) => b.orderId === orderId);
      setOrderData(orderBookings);
    }
  };

  const handleUpdateDate = (direction: 'prev' | 'next') => {
    let newDate: Date;
    if (direction === 'prev') {
      newDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - 1
      );
      setDate(newDate);
    } else {
      newDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + 1
      );
      setDate(newDate);
    }
    handleGetBookings(newDate);
  };

  const handleGetBookings = (d: Date) => {
    retreiveBookings(d);
  };

  const handleDateModalClose = (d?: Date) => {
    if (d) {
      setDate(d);
      retreiveBookings(d);
    }
    setOpen(false);
  };

  const hasActiveFilters = Object.keys(filters).reduce((value, key) => {
    if (value) return value;
    return !!filters[key as 'events' | 'customers' | 'payment'].length;
  }, false);

  const isCustomerFound = (customers: string[], fullName: string) => {
    for (let i = 0; i < customers.length; i++) {
      const lowerCaseCustomer = customers[i].toLowerCase();
      const found = fullName.toLowerCase().indexOf(lowerCaseCustomer);
      if (~found) return true;
    }
    return false;
  };

  const handleMarkProcessed = async (data: BookingEvent) => {
    await markProcessed(token, data.identifier);
    const booking = { ...data, processed: true };
    updateBooking(booking);
    const index = orderData!.findIndex((o) => o.identifier === data.identifier);
    const toUpdate = [...orderData];
    toUpdate.splice(index, 1, booking);
    setOrderData(toUpdate);
  };

  return (
    <Page fullWidth title="Bookings">
      <Drawer />
      <Card>
        <Stack distribution="fill">
          <div
            style={{
              margin: '0 auto',
              minWidth: '320px',
              width: '100%',
              maxWidth: '600px',
              padding: '0 1rem',
            }}
          >
            <TextField
              label=""
              readOnly
              value={date.toLocaleDateString('fi-Fi')}
              onChange={() => {}}
              connectedLeft={
                <>
                  <Button
                    primary={hasActiveFilters}
                    icon={FilterMajor}
                    onClick={toggleDrawer}
                  ></Button>
                  <Button
                    icon={ChevronLeftMinor}
                    onClick={() => handleUpdateDate('prev')}
                  ></Button>
                </>
              }
              connectedRight={
                <>
                  <Button
                    icon={ChevronRightMinor}
                    onClick={() => handleUpdateDate('next')}
                  ></Button>
                  <Button
                    icon={CalendarMajor}
                    onClick={() => setOpen(true)}
                  ></Button>
                </>
              }
            />
          </div>
        </Stack>
        <IndexTable
          resourceName={resourceName}
          headings={headings}
          itemCount={bookings ? bookings.length : 0}
          selectedItemsCount={0}
          onSelectionChange={handleRowClick}
          emptyState={
            <EmptyState
              heading="No events for this day yet."
              image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
            />
          }
          selectable={false}
        >
          {bookings &&
            bookings
              .filter((b) =>
                filters.events.length
                  ? filters.events.includes(b.bookingEvent!.split('-')[0] === 'custom' ? EventType.CUSTOM : b.bookingEvent!)
                  : b
              )
              .filter((b) =>
                filters.payment.length
                  ? (b.paid && filters.payment.includes(Payment.PAID)) ||
                    (!b.paid && filters.payment.includes(Payment.PRESALE))
                  : b
              )
              .filter((b) =>
                filters.customers.length
                  ? isCustomerFound(
                      filters.customers,
                      `${b.firstName} ${b.lastName}`
                    )
                  : b
              )
              .map(
                (
                  {
                    title,
                    identifier,
                    firstName,
                    lastName,
                    paid,
                    bookingEvent,
                    bookingTime,
                    processed,
                  },
                  i
                ) => (
                  <IndexTable.Row
                    id={identifier}
                    key={identifier}
                    selected={false}
                    position={i}
                  >
                    <IndexTable.Cell>
                      <span style={{ paddingLeft: '1.6rem' }}>
                        <TextStyle variation="strong">
                          {bookingEvent?.split('-')[0] === 'custom' ? <Badge status="info">{title}</Badge> : getEventTag(bookingEvent as string)}
                        </TextStyle>
                      </span>
                    </IndexTable.Cell>
                    <IndexTable.Cell>{`${firstName} ${lastName}`}</IndexTable.Cell>
                    <IndexTable.Cell>
                      {paid ? (
                        <Badge
                          size="medium"
                          status="success"
                          progress="complete"
                        >
                          Paid
                        </Badge>
                      ) : (
                        <Badge size="medium" status="new" progress="incomplete">
                          Pre-order
                        </Badge>
                      )}
                    </IndexTable.Cell>
                    <IndexTable.Cell>{bookingTime}</IndexTable.Cell>
                    <IndexTable.Cell>
                      {processed ? (
                        <Badge size="medium" status="success">
                          Processed
                        </Badge>
                      ) : (
                        '-'
                      )}
                    </IndexTable.Cell>
                  </IndexTable.Row>
                )
              )}
        </IndexTable>
        {bookings?.length ? (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              padding: ' .8rem 2rem',
              justifyContent: 'space-between',
            }}
          >
            <TextStyle>
              TASTING:
              {
                bookings
                  .filter((b) =>
                    filters.events.length
                      ? filters.events.includes(b.bookingEvent!)
                      : b
                  )
                  .filter((b) =>
                    filters.payment.length
                      ? (b.paid && filters.payment.includes(Payment.PAID)) ||
                        (!b.paid && filters.payment.includes(Payment.PRESALE))
                      : b
                  )
                  .filter((b) =>
                    filters.customers.length
                      ? isCustomerFound(
                          filters.customers,
                          `${b.firstName} ${b.lastName}`
                        )
                      : b
                  )
                  .filter((b) => b.bookingEvent === 'tasting').length
              }
            </TextStyle>
            <TextStyle>
              TOUR:
              {
                bookings
                  .filter((b) =>
                    filters.events.length
                      ? filters.events.includes(b.bookingEvent!)
                      : b
                  )
                  .filter((b) =>
                    filters.payment.length
                      ? (b.paid && filters.payment.includes(Payment.PAID)) ||
                        (!b.paid && filters.payment.includes(Payment.PRESALE))
                      : b
                  )
                  .filter((b) =>
                    filters.customers.length
                      ? isCustomerFound(
                          filters.customers,
                          `${b.firstName} ${b.lastName}`
                        )
                      : b
                  )
                  .filter((b) => b.bookingEvent === 'tour').length
              }
            </TextStyle>
            <TextStyle>
              CUSTOM:
              {
                bookings
                  .filter((b) =>
                    filters.events.length
                      ? filters.events.includes(b.bookingEvent!)
                      : b
                  )
                  .filter((b) =>
                    filters.payment.length
                      ? (b.paid && filters.payment.includes(Payment.PAID)) ||
                        (!b.paid && filters.payment.includes(Payment.PRESALE))
                      : b
                  )
                  .filter((b) =>
                    filters.customers.length
                      ? isCustomerFound(
                          filters.customers,
                          `${b.firstName} ${b.lastName}`
                        )
                      : b
                  )
                  .filter((b) => b.bookingEvent?.split('-')[0] === 'custom').length
              }
            </TextStyle>
            <Heading>
              TOTAL:{' '}
              {
                bookings
                  .filter((b) =>
                    filters.events.length
                      ? filters.events.includes(b.bookingEvent!)
                      : b
                  )
                  .filter((b) =>
                    filters.payment.length
                      ? (b.paid && filters.payment.includes(Payment.PAID)) ||
                        (!b.paid && filters.payment.includes(Payment.PRESALE))
                      : b
                  )
                  .filter((b) =>
                    filters.customers.length
                      ? isCustomerFound(
                          filters.customers,
                          `${b.firstName} ${b.lastName}`
                        )
                      : b
                  ).length
              }
            </Heading>
          </div>
        ) : null}
      </Card>
      <Modal
        open={!!orderData.length}
        title={(orderData.length && `Order #${orderData[0].orderNumber}`) || ''}
        onClose={() => setOrderData([])}
        large
      >
        <Modal.Section>
          {orderData.length ? (
            <>
              <TextContainer>
                View order
                <Button
                  plain
                  onClick={() =>
                    (window.top.location.href = `https://kyro-distillery-home.myshopify.com/admin/orders/${orderData[0].orderId}`)
                  }
                >
                  #{orderData[0].orderNumber}
                </Button>
              </TextContainer>
              <Stack>
                <Stack.Item fill>
                  <Heading>Bookings belonging to order:</Heading>
                </Stack.Item>
              </Stack>
              <Card>
                <DataTable
                  columnContentTypes={[
                    'text',
                    'text',
                    'text',
                    'text',
                    'text',
                    'text',
                  ]}
                  headings={[
                    'Event',
                    'Customer',
                    'Email',
                    'Payment',
                    'Time',
                    'Processed',
                  ]}
                  rows={orderData.map((o) => [
                    o.bookingEvent?.split('-')[0] === 'custom' ? `${o.title}`: o.bookingEvent,
                    `${o.firstName} ${o.lastName}`,
                    o.email,
                    o.paid ? 'paid' : 'preorder',
                    o.bookingTime,
                    o.processed ? (
                      <>
                        <Badge size="medium" status="success">
                          Processed
                        </Badge>
                      </>
                    ) : (
                      <Button primary onClick={() => handleMarkProcessed(o)}>
                        Mark as processed
                      </Button>
                    ),
                  ])}
                />
              </Card>
            </>
          ) : null}
        </Modal.Section>
      </Modal>
      <DatePickerModal
        open={open}
        date={date}
        onClose={(d?: Date) => handleDateModalClose(d)}
      />
    </Page>
  );
};

export default Bookings;
