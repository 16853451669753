import { Banner } from '@shopify/polaris';
import classes from './Error.module.css';

const Error = () => {
  return (
    <div className={classes.container}>
      <Banner title="Application error" status="critical">
        <p>
          Something went wrong while loading the application. Please try
          reloading the page.
        </p>
      </Banner>
    </div>
  );
};

export default Error;
