import React, { useCallback, useEffect, useState } from 'react';
import { Modal, DatePicker } from '@shopify/polaris';
import { getBookings } from '../api';
import { BookingEvent } from '../api/interfaces/booking-event';

let loading = false;
function useBookings(token: string, date: Date) {
  const [error, setError] = useState('');
  const [bookings, setBookings] = useState<BookingEvent[]>();

  const fetchBookings = useCallback(
    async (d: null | Date) => {
      try {
        loading = true;
        const data = await getBookings(
          token,
          Math.floor((d ? d.getTime() : date.getTime()) / 1000)
        );
        setBookings(data || []);
      } catch (e) {
        setError(e.message);
      } finally {
        loading = false;
      }
    },
    [date, token]
  );

  const updateBooking = (booking: BookingEvent) => {
    const index = bookings!.findIndex((b) => b.variantId === booking.variantId);
    const toUpdate = [...bookings!];
    toUpdate.splice(index, 1, booking);
    setBookings(toUpdate);
  };

  const retreiveBookings = (d: Date) => {
    fetchBookings(d);
  };

  const DatePickerModal = ({
    open,
    date,
    onClose,
  }: {
    open: boolean;
    date: Date;
    onClose: (d?: Date) => void;
  }) => {
    const d = new Date();

    const [selected, setSelected] = useState(d);
    const [year, setYear] = useState(d.getFullYear());
    const [month, setMonth] = useState(d.getMonth());
    useEffect(() => {
      setSelected(date);
      setYear(date.getFullYear());
      setMonth(date.getMonth());
    }, [date]);

    return (
      <Modal
        title="Select date"
        open={open}
        onClose={() => onClose()}
        primaryAction={{
          content: 'Select',
          onAction: () => onClose(selected),
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => onClose(),
          },
        ]}
      >
        <Modal.Section>
          <DatePicker
            month={month}
            year={year}
            onChange={({ start }) => setSelected(start)}
            onMonthChange={(m, y) => {
              setMonth(m);
              setYear(y);
            }}
            selected={selected}
          />
        </Modal.Section>
      </Modal>
    );
  };

  useEffect(() => {
    if (token && !bookings && !loading) {
      fetchBookings(null);
    }
  }, [fetchBookings, bookings, token]);

  return {
    loading,
    error,
    bookings,
    updateBooking,
    retreiveBookings,
    DatePickerModal,
  };
}

export default useBookings;
