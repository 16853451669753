import { useCallback, useEffect, useState } from 'react';
import { getCountries } from '../api';
import { Country } from '../api/interfaces/countries';

function useCountries(token: string) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [countries, setCountries] = useState<Country>();

  const fetchCountries = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getCountries(token);
      setCountries(data);
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    if (token && !countries && !loading) {
        fetchCountries();
    }
  }, [fetchCountries, loading, countries, token]);

  return { loading, error, countries };
}

export default useCountries;
