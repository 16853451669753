import {
  SkeletonPage,
  SkeletonDisplayText,
  SkeletonBodyText,
  Card,
  Page,
  IndexTable,
  TextStyle,
  Image,
  Badge,
} from '@shopify/polaris';
import { useToken } from '../context/AuthContext';
import useProducts from '../hooks/useProducts';
import { IndexTableHeading } from '@shopify/polaris/dist/types/latest/src/components/IndexTable';
import { NonEmptyArray } from '@shopify/polaris/dist/types/latest/src/types';
import { Metafield, Product } from '../api/interfaces/product';
import placeholder from '../assets/image.jpg';
import { useState } from 'react';
import { updateProductMetafield } from '../api';
import { asProductId } from '../utils';
import BookingModal from '../components/booking-modal/BookingModal';

const headings: NonEmptyArray<IndexTableHeading> = [
  { title: 'Image' },
  { title: 'Name' },
  { title: 'Bookable' },
];

const resourceName = {
  singular: 'booking',
  plural: 'bookings',
};

const ProductsLoading = () => {
  return (
    <SkeletonPage fullWidth title="Products">
      <Card>
        <Card.Section>
          <SkeletonDisplayText size="medium" />
        </Card.Section>
        <Card.Section>
          <SkeletonBodyText lines={2} />
        </Card.Section>
        <Card.Section>
          <SkeletonBodyText lines={2} />
        </Card.Section>
      </Card>
    </SkeletonPage>
  );
};

const Products = () => {
  const token = useToken();
  const { loading, products, updateProduct } = useProducts(token);
  const [bookingModalOpen, setBookingModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product>();

  const handleRowClick = (...params: any) => {
    const productId = params[2];
    const product = products?.find((p) => p.node.id === productId);
    if (product) {
      setSelectedProduct(product);
      setBookingModalOpen(true);
    }
  };

  const handleModalClose = async (id?: string, meta?: Metafield) => {
    if (meta) {
      const metafield: Record<string, unknown> = {
        namespace: 'kyro_booking',
        key: 'kyro_booking',
        value: meta.value,
        type: 'json',
      };

      if (
        selectedProduct?.node.metafield &&
        selectedProduct?.node.metafield.id
      ) {
        metafield.id = selectedProduct?.node.metafield.id;
      }

      await updateProductMetafield(
        token,
        asProductId(selectedProduct!.node.id),
        { input: { id, metafields: [metafield] } }
      );

      updateProduct({
        ...selectedProduct!,
        node: {
          ...selectedProduct!.node,
          metafield: { ...selectedProduct!.node.metafield, ...metafield },
        },
      });
    }
    setBookingModalOpen(false);
    setSelectedProduct(undefined);
  };

  const isBookable = (meta: Metafield) =>
    meta && meta.namespace === 'kyro-booking' && meta.value.length;

  if (loading) return <ProductsLoading />;
  return (
    <Page fullWidth title="Bookings">
      <Card>
        <IndexTable
          resourceName={resourceName}
          headings={headings}
          itemCount={(products && products.length) || 0}
          selectedItemsCount={0}
          onSelectionChange={handleRowClick}
          emptyState={<p>No products</p>}
          selectable={false}
        >
          {products &&
            products.map(({ image, node }, i) => {
              const { id, title, metafield } = node;
              return (
                <IndexTable.Row id={id} key={id} selected={false} position={i}>
                  <IndexTable.Cell>
                    <span style={{ paddingLeft: '1.6rem' }}>
                      <Image
                        source={image || placeholder}
                        alt={title}
                        style={{ width: 72, height: 'auto' }}
                      />
                    </span>
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <TextStyle variation="strong">{title}</TextStyle>
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <TextStyle variation="strong">
                      {isBookable(metafield) ? (
                        <Badge status="success">Yes</Badge>
                      ) : (
                        '-'
                      )}
                    </TextStyle>
                  </IndexTable.Cell>
                </IndexTable.Row>
              );
            })}
        </IndexTable>
      </Card>
      {bookingModalOpen && (
        <BookingModal
          product={selectedProduct}
          open={bookingModalOpen}
          onClose={handleModalClose}
        />
      )}
    </Page>
  );
};

export default Products;
