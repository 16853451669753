import { useCallback, useEffect, useState } from 'react';
import { getProducts } from '../api';
import { Product } from '../api/interfaces/product';

function useProducts(token: string) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [products, setProducts] = useState<Product[]>();

  const fetchProducts = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getProducts(token);
      setProducts(data);
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }, [token]);

  const updateProduct = (product: Product) => {
    const index = products!.findIndex((p) => p.node.id === product.node.id);
    const toUpdate = [...products!];
    toUpdate.splice(index, 1, product);
    setProducts(toUpdate);
  };

  useEffect(() => {
    if (token && !products && !loading) {
      fetchProducts();
    }
  }, [fetchProducts, loading, products, token]);

  return { loading, error, products, updateProduct };
}

export default useProducts;
