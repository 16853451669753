import React, { SyntheticEvent, useState } from 'react';
import { Stack, Subheading, TextField } from '@shopify/polaris';
import classes from './FormField.module.css';

type FormFieldProps = {
  onSubmit: (c: string) => void;
};
const FormField = (props: FormFieldProps) => {
  const { onSubmit } = props;
  const [customer, setCustomer] = useState('');

  const handleAddCustomer = (evt: SyntheticEvent) => {
    evt.preventDefault();
    if (customer.length) {
      onSubmit(customer);
      setCustomer('');
    }
  };
  return (
    <form className={classes.filter} onSubmit={handleAddCustomer}>
      <Subheading>Customers</Subheading>
      <Stack vertical distribution="center">
        <Stack.Item>
          <TextField
            label="Name to filter by"
            value={customer}
            onChange={(c) => setCustomer(c)}
          />
        </Stack.Item>
      </Stack>
    </form>
  );
};

export default FormField;
