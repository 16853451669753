import { useCallback, useEffect, useState } from 'react';
import { getCustomers } from '../api';
import { Customer} from '../api/interfaces/customer';

function useCustomers(token: string) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [customers, setCustomers] = useState<Customer[]>();

  const fetchCustomers = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getCustomers(token);
      setCustomers(data);
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    if (token && !customers && !loading) {
        fetchCustomers();
    }
  }, [fetchCustomers, loading, customers, token]);

  return { loading, error, customers };
}

export default useCustomers;
