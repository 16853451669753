import { Redirect, Route, Switch } from 'react-router';
import Bookings from './views/Bookings';
import Products from './views/Products';
import Neworder from './views/Neworder';
import Install from './views/Install';

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Bookings} />
      <Route exact path="/products" component={Products} />
      <Route exact path="/neworder" component={Neworder} />
      <Route path="/install" component={Install} />
      <Redirect to="/" />
    </Switch>
  );
};

export default Routes;
