import { QueryParams } from '../hooks/useQueryParams';

const apiUrl = process.env.REACT_APP_API_HOST;

export const authenticate = async (payload: QueryParams) => {
  const url = `${apiUrl}auth`;
  const res = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  const data = await res.json();

  if (res.status >= 400) throw new Error(data.message);
  if (data.authUrl) window.location.href = data.authUrl;
  return data;
};

export const install = async (payload: QueryParams) => {
  const url = `${apiUrl}install`;
  const res = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (res.status >= 400) throw new Error();

  if (res.status === 200)
    window.location.href = `https://${payload.shop}/admin/apps`;
};

export const getProducts = async (token: string) => {
  const url = `${apiUrl}products`;
  const res = await fetch(url, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });

  const data = await res.json();
  if (res.status >= 400) throw new Error(data.message);
  return data;
};

export const getBookings = async (token: string, start = 0) => {
  const url = `${apiUrl}bookings${
    start ? `?start=${start}` : ''
  }`;
  const res = await fetch(url, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });

  const data = await res.json();
  if (res.status >= 400) throw new Error(data.message);
  return data;
};

export const markProcessed = async (token: string, id: string) => {
  const url = `${apiUrl}bookings/process?id=${id}`;
  const res = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });

  const data = await res.json();
  if (res.status >= 400) throw new Error(data.message);
  return true;
};

export const createOrder = async (token: string, order: any) => {
  const url = `${apiUrl}createOrder`;
  const res = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(order),
  });

  const data = await res.json();
  if (res.status >= 400) throw new Error(data.message);
  return true;
};


export const updateProductMetafield = async (
  token: string,
  productId: number,
  metafield: any
) => {
  const url = `${apiUrl}products/${productId}`;
  const res = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify({ metafield }),
  });

  const data = await res.json();
  if (res.status >= 400) throw new Error(data.message);
  return data;
};

export const getReservedSlots = async (token: string,start: number, end:number) => {
  const res = await fetch(`${apiUrl}booking-slots?start=${start}&end=${end}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });

  const data = await res.json();
  if (res.status >= 400) throw new Error(data.message);
  return data;
}

export const getCustomers = async (token: string) => {
  const url = `${apiUrl}customers`;
  const res = await fetch(url, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });

  const data = await res.json();
  if (res.status >= 400) throw new Error(data.message);
  return data;
};

export const getCountries = async (token: string) => {
  const url = `${apiUrl}countries`;
  const res = await fetch(url, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });

  const data = await res.json();
  if (res.status >= 400) throw new Error(data.message);
  return data;
};