import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Heading,
  Stack,
  Subheading,
  Tag,
} from '@shopify/polaris';
import { MobileCancelMajor } from '@shopify/polaris-icons';
import classes from './Drawer.module.css';
import { EventType } from '../api/interfaces/booking';
import FormField from '../components/form-field/FormField';

export enum Payment {
  PAID = 'paid',
  PRESALE = 'presale',
}

type Filters = {
  events: EventType[];
  customers: string[];
  payment: Payment[];
};

function useDrawer() {
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState<Filters>({
    events: [],
    customers: [],
    payment: [],
  });

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const isSelected = (
    key: 'events' | 'customers' | 'payment',
    value: string | boolean
  ) => {
    const mapper = {
      events: filters['events']!.includes(value as EventType),
      customers: filters['customers']!.includes(value as string),
      payment: filters['payment']!.includes(value as Payment),
    };

    return mapper[key] as boolean;
  };

  const Drawer = () => {
    return (
      <div
        className={`${classes.drawer}`}
        style={{ transform: `translateX(${open ? '0' : '300'}px)` }}
      >
        <div className={classes.header}>
          <Heading>Manage filters</Heading>
          <Button plain icon={MobileCancelMajor} onClick={toggleDrawer} />
        </div>
        <div className={classes.filter}>
          <Subheading>Events</Subheading>
          <Stack vertical distribution="center">
            {Object.values(EventType).map((e) => (
              <Stack.Item key={e}>
                <Checkbox
                  label={e}
                  id={e}
                  checked={isSelected('events', e)}
                  onChange={(checked, id) => {
                    if (!checked) {
                      return setFilters({
                        ...filters,
                        events: [...filters['events'].filter((f) => f !== id)],
                      });
                    }

                    setFilters({
                      ...filters,
                      events: [...filters['events'], id as EventType],
                    });
                  }}
                />
              </Stack.Item>
            ))}
          </Stack>
        </div>
        <div className={classes.filter}>
          <Subheading>Payment status</Subheading>
          <Stack vertical distribution="center">
            {Object.values(Payment).map((p) => (
              <Stack.Item key={p}>
                <Checkbox
                  label={p}
                  id={p}
                  checked={isSelected('payment', p)}
                  onChange={(checked, id) => {
                    if (!checked) {
                      return setFilters({
                        ...filters,
                        payment: [
                          ...filters['payment'].filter((f) => f !== id),
                        ],
                      });
                    }

                    setFilters({
                      ...filters,
                      payment: [...filters['payment'], id as Payment],
                    });
                  }}
                />
              </Stack.Item>
            ))}
          </Stack>
        </div>
        <FormField
          onSubmit={(c) => {
            if (!filters['customers'].includes(c)) {
              setFilters({ ...filters, customers: [...filters.customers, c] });
            }
          }}
        />

        <Stack>
          {filters['customers'].map((c) => (
            <Stack.Item key={c}>
              <Tag
                onRemove={() =>
                  setFilters({
                    ...filters,
                    customers: filters.customers.filter((cust) => cust !== c),
                  })
                }
              >
                {c}
              </Tag>
            </Stack.Item>
          ))}
        </Stack>
      </div>
    );
  };

  return {
    open,
    setOpen,
    filters,
    toggleDrawer,
    Drawer,
  };
}

export default useDrawer;
