import {
  Modal,
  TextContainer,
  TextStyle,
  Heading,
  Button,
  Form,
  Label,
  Tag,
  TextField,
  Select,
  Card,
  SettingToggle,
} from '@shopify/polaris';
import {
  PlusMinor,
  CancelSmallMinor,
  DeleteMajor,
  TickMinor,
} from '@shopify/polaris-icons';

import { useEffect, useState } from 'react';
import { Booking, EventType } from '../../api/interfaces/booking';
import { Metafield, Product } from '../../api/interfaces/product';
import DatePicker from '../date-picker/DatePicker';

type BookingModalProps = {
  open: boolean;
  product?: Product;
  onClose: (id?: string, meta?: Metafield) => void;
};

const defaultBooking = {
  start: undefined,
  end: undefined,
  eventType: EventType.DISTILLERY_TOUR,
  slots: {
    mon: [],
    tue: [],
    wed: [],
    thu: [],
    fri: [],
    sat: [],
    sun: [],
  },
};

const BookingModal = (props: BookingModalProps) => {
  const defaultException = {
    id: Math.random().toString(36).substr(2, 5),
    participants: 0,
    days: Array<any>(),
  };

  const [exceptionMetafields, setExceptionMetafields] = useState([
    defaultException,
  ]);

  const { open, product, onClose } = props;
  const [active, setActive] = useState(false);
  const [bookingMetafields, setBookingMetafields] = useState<Booking[]>([]);
  const [newSlot, setNewSlot] = useState('');
  const [creatingSlot, setCreatingSlot] = useState<{
    index: number;
    key: string;
  }>({ index: -1, key: '' });
  const [freeSpots, setFreeSpots] = useState(60);
  const [maxOrderItems, setMaxOrderItems] = useState(5);
  const [closedDates, setClosedDates] = useState<string[]>([]);
  const [closedDay, setClosedDay] = useState<Date>();
  const [eventType, setEventType] = useState<EventType>(
    EventType.DISTILLERY_TOUR
  );
  const [removeIndex, setRemoveIndex] = useState<number>();
  const [removeExIndex, setRemoveExIndex] = useState<number>();
  const [identifier, setIndentifier] = useState<string>();

  useEffect(() => {
    if (product && product.node.metafield) {
      const json = product!.node.metafield.value.length
        ? JSON.parse(product!.node.metafield.value)
        : null;

      if (json) {
        setActive(json.active);
        setBookingMetafields(json.booking);
        setExceptionMetafields(json.exceptions || []);
        setFreeSpots(json.freeSpots);
        setMaxOrderItems(json.maxOrderItems || 5);
        setClosedDates(json.closedDates);
        setEventType(json.eventType || EventType.DISTILLERY_TOUR);
        setIndentifier(
          json.identifier || Math.random().toString(36).substr(2, 5)
        );
      }
    }
  }, [product]);

  const handleActive = () => {
    setActive((active) => !active);
  };

  const textStatus = active ? 'activated' : 'deactivated';
  const contentStatus = active ? 'Deactivate' : 'Activate';

  const handleChange = (index: number, data: any, field: string) => {
    const copy = JSON.parse(JSON.stringify(bookingMetafields));
    copy[index][field] = data;
    setBookingMetafields(copy);
  };
  const handleRemoveBooking = (index: number) => {
    setBookingMetafields(bookingMetafields.filter((_, i) => i !== removeIndex));
    setRemoveIndex(undefined);
  };
  const handleAddBooking = () => {
    setBookingMetafields([...bookingMetafields, defaultBooking]);
  };

  const handleRemoveException = (index: number) => {
    setExceptionMetafields(
      exceptionMetafields.filter((_, i) => i !== removeExIndex)
    );
    setRemoveExIndex(undefined);
  };

  const handleAddException = () => {
    setExceptionMetafields([...exceptionMetafields, defaultException]);
  };

  const updateExceptionParticipants = (index: number, amount: number) => {
    const newEM = [...exceptionMetafields];
    newEM[index].participants = amount;
    setExceptionMetafields(newEM);
  };

  const addExceptionDays = (index: number, day: number) => {
    const newEM = [...exceptionMetafields];
    const newDayStr = new Date(day);
    let month = newDayStr.getMonth();
    month = month + 1;
    let newDay = {
      date: day,
      dateStr:
        newDayStr.getDate() + '.' + month + '.' + newDayStr.getFullYear(),
    };
    newEM[index].days.push(newDay);
    setExceptionMetafields(newEM);
  };

  const removeExceptionDays = (index: number, eId: number) => {
    const newEM = [...exceptionMetafields];
    newEM[eId].days.splice(index, 1);
    setExceptionMetafields(newEM);
  };

  const handleAddClosedDay = (d: number) => {
    setClosedDay(undefined);
    if (!isNaN(d)) {
      const date = new Date(d);
      const dateStr = `${date.getDate()}.${
        date.getMonth() + 1
      }.${date.getFullYear()}`;
      setClosedDates([...closedDates, dateStr]);
    }
  };

  const handleRemoveSlot = (index: number, key: string, id: number) => {
    setBookingMetafields(
      bookingMetafields.map((bm: Booking, i: number) => {
        if (i !== index) return bm;
        return {
          ...bm,
          slots: {
            ...bm.slots,
            [key]: bm.slots[key].filter((s, idx) => idx !== id),
          },
        };
      })
    );
  };

  const handleAddSlot = (index: number, key: string) => {
    setBookingMetafields(
      bookingMetafields.map((bm: Booking, i: number) => {
        if (i !== index) return bm;
        return {
          ...bm,
          slots: {
            ...bm.slots,
            [key]: [...bm.slots[key], newSlot],
          },
        };
      })
    );

    setNewSlot('');
    handleCreateSlot(-1, '');
  };

  const handleCreateSlot = (index: number, key: string) =>
    setCreatingSlot({ index, key });

  const eventOptions = Object.values(EventType).map((o) => ({
    label: o,
    value: o,
  }));

  return (
    <Modal
      large
      open={open}
      title=""
      onClose={() => onClose()}
      primaryAction={{
        content: 'Save',
        onAction: () =>
          onClose(product!.node.id, {
            ...product!.node.metafield,
            value: JSON.stringify({
              active: active,
              booking: [...bookingMetafields],
              freeSpots,
              maxOrderItems,
              exceptions: [...exceptionMetafields],
              closedDates,
              eventType,
              identifier,
            }),
          }),
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: () => onClose(),
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <Heading>Edit Booking settings for {product?.node.title}</Heading>
        </TextContainer>
      </Modal.Section>
      <Modal.Section>
        <Form onSubmit={() => null}>
          <SettingToggle
            action={{
              content: contentStatus,
              onAction: handleActive,
            }}
            enabled={active}
          >
            Booking is <TextStyle variation="strong">{textStatus}</TextStyle>.
          </SettingToggle>

          <div style={{ marginBottom: '2rem' }}>
            <Heading>Settings</Heading>
          </div>

          <div style={{ marginBottom: '2rem' }}>
            <Select
              label="Event type"
              value={eventType}
              options={eventOptions}
              onChange={(val) => setEventType(val as EventType)}
            />
          </div>
          <div style={{ marginBottom: '2rem' }}>
            <TextStyle variation="strong">Maxiumum event spots</TextStyle>
            <TextField
              label="Event spots"
              value={freeSpots.toString()}
              onChange={(val) => setFreeSpots(parseInt(val))}
              type="number"
            />
          </div>
          <div style={{ marginBottom: '2rem' }}>
            <TextStyle variation="strong">
              Maxiumum orders by single customer
            </TextStyle>
            <TextField
              label="Maximum items in an order"
              value={maxOrderItems.toString()}
              onChange={(val) => setMaxOrderItems(parseInt(val))}
              type="number"
            />
          </div>
          <div style={{ display: 'flex' }}>
            <span style={{ flex: 1 }}>
              <TextStyle variation="strong">Bookings</TextStyle>
            </span>
            <Button primary onClick={handleAddBooking}>
              Add
            </Button>
          </div>
          {bookingMetafields.map((booking, i) => (
            <div
              style={{
                padding: '.8rem',
                background: '#eee',
                margin: '.8rem 0',
              }}
              key={i}
            >
              <div style={{ display: 'flex' }}>
                <span style={{ flex: 1 }}>
                  <TextStyle variation="strong">Active time</TextStyle>
                </span>
                {removeIndex !== i && (
                  <span>
                    <Button
                      icon={DeleteMajor}
                      destructive
                      onClick={() => {
                        setRemoveIndex(i);
                      }}
                    ></Button>
                  </span>
                )}
                {removeIndex === i && (
                  <span>
                    <span style={{ marginRight: '.4rem' }}>Really remove?</span>
                    <Button destructive onClick={() => handleRemoveBooking(i)}>
                      Yes
                    </Button>
                    <Button
                      onClick={() => {
                        setRemoveIndex(undefined);
                      }}
                    >
                      Cancel
                    </Button>
                  </span>
                )}
              </div>

              <div style={{ display: 'flex', margin: '.8rem 0' }}>
                <div style={{ flex: 1, padding: '0 .4rem' }}>
                  <Label id="start">Start</Label>
                  <div style={{ display: 'flex' }}>
                    <div>
                      <DatePicker
                        date={booking.start || undefined}
                        onClear={() => handleChange(i, undefined, 'start')}
                        onChange={(date: number) =>
                          handleChange(i, date, 'start')
                        }
                      />
                    </div>
                    <div>
                      <Button
                        icon={CancelSmallMinor}
                        onClick={() => handleChange(i, undefined, 'start')}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ flex: 1, padding: '0 .4rem' }}>
                  <Label id="start">End</Label>
                  <div style={{ display: 'flex' }}>
                    <div>
                      <DatePicker
                        date={booking.end || undefined}
                        onClear={() => handleChange(i, undefined, 'end')}
                        onChange={(date: number) =>
                          handleChange(i, date, 'end')
                        }
                      />
                    </div>
                    <div>
                      <Button
                        icon={CancelSmallMinor}
                        onClick={() => handleChange(i, undefined, 'end')}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <TextStyle variation="strong">Booking slots</TextStyle>
              <div style={{ padding: '0 .4rem', margin: '.8rem 0' }}>
                {Object.keys(booking.slots).map((key) => (
                  <div key={key} style={{ margin: '.8rem 0', display: 'flex' }}>
                    <div style={{ flex: 1, minHeight: 48 }}>
                      <Label id={`${key}-${i}`}>
                        <span style={{ textTransform: 'capitalize' }}>
                          {key}
                        </span>
                      </Label>
                      {booking.slots[key].length
                        ? booking.slots[key].map((slot, idx) => (
                            <span
                              key={`${slot}-${i}`}
                              style={{ margin: '.4rem' }}
                            >
                              <Tag
                                onRemove={() => handleRemoveSlot(i, key, idx)}
                              >
                                {slot}
                              </Tag>
                            </span>
                          ))
                        : '-'}
                    </div>
                    {creatingSlot.index === i && creatingSlot.key === key ? (
                      <div
                        style={{
                          display: 'flex',
                          maxWidth: 136,
                          alignItems: 'center',
                        }}
                      >
                        <TextField
                          label=""
                          labelHidden
                          value={newSlot}
                          onChange={setNewSlot}
                        />
                        <Button
                          icon={TickMinor}
                          disabled={newSlot.length < 5 || newSlot.length > 5}
                          onClick={() => handleAddSlot(i, key)}
                          primary
                        />
                        <Button
                          icon={CancelSmallMinor}
                          onClick={() => {
                            setNewSlot('');
                            handleCreateSlot(-1, '');
                          }}
                        />
                      </div>
                    ) : (
                      <Button
                        icon={PlusMinor}
                        onClick={() => handleCreateSlot(i, key)}
                      ></Button>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
          <div style={{ display: 'flex' }}>
            <span style={{ flex: 1 }}>
              <TextStyle variation="strong">Exceptions</TextStyle>
            </span>
            <Button primary onClick={handleAddException}>
              Add
            </Button>
          </div>
          <Card>
            <div style={{ padding: '10px' }}>
              {exceptionMetafields.map((ex, i) => (
                <Card.Section key={ex.id}>
                  <div style={{ padding: '10px', display: 'flex' }}>
                    <div style={{ minHeight: 48 }}>
                      <TextField
                        label="Participants"
                        value={ex.participants.toString()}
                        onChange={(value) =>
                          updateExceptionParticipants(i, parseInt(value))
                        }
                        type="number"
                      />
                    </div>
                    <div style={{ flex: 1, minHeight: 48 }}>
                      <Label id="exception2">
                        <span style={{ textTransform: 'capitalize' }}>
                          Days
                        </span>
                      </Label>
                      {ex.days.length ? (
                        ex.days.map((ed, id) => (
                          <div
                            key={id}
                            style={{ margin: '5px', display: 'inline-block' }}
                          >
                            <Tag onRemove={() => removeExceptionDays(id, i)}>
                              {ed.dateStr}
                            </Tag>
                          </div>
                        ))
                      ) : (
                        <p style={{ margin: '5px', display: 'inline-block' }}>
                          No dates selected
                        </p>
                      )}
                    </div>
                    <div
                      style={{
                        minHeight: 48,
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                      }}
                    >
                      <DatePicker
                        pickerStyle="exception"
                        onChange={(date: number) => addExceptionDays(i, date)}
                      />
                      {removeExIndex !== i && (
                        <span>
                          <Button
                            icon={DeleteMajor}
                            destructive
                            onClick={() => {
                              setRemoveExIndex(i);
                            }}
                          ></Button>
                        </span>
                      )}
                      {removeExIndex === i && (
                        <span>
                          <span style={{ marginRight: '.4rem' }}>
                            Really remove?
                          </span>
                          <Button
                            destructive
                            onClick={() => handleRemoveException(i)}
                          >
                            Yes
                          </Button>
                          <Button
                            onClick={() => {
                              setRemoveExIndex(undefined);
                            }}
                          >
                            Cancel
                          </Button>
                        </span>
                      )}
                    </div>
                  </div>
                </Card.Section>
              ))}
            </div>
          </Card>
          <div style={{ margin: '2rem 0' }}>
            <TextStyle variation="strong">Closed booking days</TextStyle>
            <Label id="start">Add Closed day</Label>
            <DatePicker
              date={closedDay?.getTime()}
              onClear={() => setClosedDay(undefined)}
              onChange={handleAddClosedDay}
            />
            <div style={{ marginTop: '2rem' }}>
              {closedDates.length
                ? closedDates.map((cd) => (
                    <span key={cd} style={{ margin: '.4rem' }}>
                      <Tag
                        onRemove={() =>
                          setClosedDates(closedDates.filter((c) => c !== cd))
                        }
                      >
                        {cd}
                      </Tag>
                    </span>
                  ))
                : '-'}
            </div>
          </div>
        </Form>
      </Modal.Section>
    </Modal>
  );
};

export default BookingModal;
