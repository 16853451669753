export enum EventType {
    DISTILLERY_TOUR = 'tour',
    TASTING = 'tasting',
    BRUNCH = 'brunch',
    CUSTOM = 'custom',
  }

  interface ExceptionSlotData {
    [key: string]: string[];
  }

  export interface Exception {
    eventType: EventType;
    exceptions: ExceptionSlotData;
  }