import {
  createContext,
  Dispatch,
  ReactElement,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

type AuthProviderType = {
  children: ReactElement;
  token: string;
  shop: string;
};

type AuthContextType = {
  appShop: string;
  setAppShop: Dispatch<SetStateAction<string>>;
  appToken: string;
  setAppToken: Dispatch<SetStateAction<string>>;
};

const ctxDefaultValue: AuthContextType = {
  appShop: '',
  setAppShop: () => {},
  appToken: '',
  setAppToken: () => {},
};

const AuthContext = createContext(ctxDefaultValue);

export const useShop = () => {
  const { appShop } = useContext(AuthContext);
  return appShop;
};

export const useToken = () => {
  const { appToken } = useContext(AuthContext);
  return appToken;
};

const AuthProvider = ({ token, shop, children }: AuthProviderType) => {
  const [appToken, setAppToken] = useState(ctxDefaultValue.appToken);
  const [appShop, setAppShop] = useState(ctxDefaultValue.appShop);

  useEffect(() => {
    setAppToken(token);
  }, [token]);

  useEffect(() => {
    setAppShop(shop);
  }, [shop]);

  return (
    <AuthContext.Provider
      value={{
        appShop,
        setAppShop,
        appToken,
        setAppToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
