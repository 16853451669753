import {
  Card,
  Page,
  TextStyle,
  FormLayout,
  TextField,
  Button,
  Select,
  Heading,
  Scrollable,
  ResourceList,
  ResourceItem,
  Avatar,
  Layout,
  Toast,
} from '@shopify/polaris';
import { DeleteMajor } from '@shopify/polaris-icons';
import { useToken } from '../context/AuthContext';
import useProducts from '../hooks/useProducts';
import useCustomers from '../hooks/useCustomers';
import { useState } from 'react';
import { createOrder, getReservedSlots } from '../api';
import DatePicker from '../components/date-picker/DatePicker';
import {
  CustomerSearchResult,
  PreviousCustomer,
} from '../api/interfaces/customer';
import useCountries from '../hooks/useCountries';

const Neworder = () => {
  const token = useToken();

  const weekDayNames = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

  const defaultListItem = {
    id: Math.random().toString(36).substr(2, 5),
    variantId: 'vt',
    quantity: 0,
    key: 'booking',
    eventType: '',
    booking: [{ time: '', freespots: '' }],
    dayInfo: Array<any>(),
    reservedInApp: Array<any>(),
    closedDay: false,
    errors: { productError: '', quantityError: '' },
  };
  const defaultMetafield = { metafield: '' };
  const defaultDate = { date: new Date(), dateStr: '' };
  const defaultTime = { time: '00:00' };

  const { products } = useProducts(token);
  const { customers } = useCustomers(token);
  const { countries } = useCountries(token);
  const [error, setError] = useState(false);
  const [listItems, setListItems] = useState([defaultListItem]);
  const [selectedMetafield, setSeletedMetafield] = useState([defaultMetafield]);
  const [selectedDate, setSelectedDate] = useState([defaultDate]);
  const [selectedTime, setSelectedTime] = useState([defaultTime]);
  const [create, setCreate] = useState(false);
  const [removeIndex, setRemoveIndex] = useState<number>();
  const [searchFirstName, setSearchFirstName] = useState('');
  const [searchLastName, setSearchLastName] = useState('');
  const [previousCustomer, setPreviousCustomer] = useState<PreviousCustomer>();
  const [searchResult, setSearchResult] = useState<CustomerSearchResult[]>();
  const [orderProcessed, setOrderProcessed] = useState(false);

  const productOptions = [
    { label: 'Valitse tuote', value: 'vt', metafield: '' },
  ];

  products?.forEach((product, i) => {
    let bookingInfo = '';
    let freeVariant = '';
    if (product.node.metafield != null) {
      bookingInfo = product.node.metafield.value;
    }
    product.variants.forEach(
      (item) => item.node.price === '0.00' && (freeVariant = item.node.id)
    );
    productOptions.push({
      label: product.node.title,
      value: freeVariant,
      metafield: bookingInfo,
    });
  });

  const editedCountries = countries?.__type.states.map(
    ({ name: value, description: label }) => ({
      label: label.slice(0, -1),
      value,
    })
  ) ?? [
    { label: 'Finland', value: 'FI' },
    { label: 'Other countries not found', value: 'not found' },
  ];

  const customerFields = [
    { name: 'firstName', label: 'Firstname', value: '', error: '' },
    { name: 'lastName', label: 'Lastname', value: '', error: '' },
    { name: 'address1', label: 'Address', value: '', error: '' },
    { name: 'zip', label: 'Zip', value: '', error: '' },
    { name: 'city', label: 'City', value: '', error: '' },
    { name: 'country', label: 'Country', value: 'FI', error: '' },
    { name: 'note', label: 'Additional information', value: '', error: '' },
  ];

  const [customerInfo, setCustomerInfo] = useState(customerFields);

  const clearPreviousCustomer = () => {
    setPreviousCustomer(undefined);
    setCustomerInfo(customerFields);
    setSearchResult(undefined);
  };

  const chooseCustomer = (data: CustomerSearchResult) => {
    const updateCustomerInfo = [...customerInfo];
    updateCustomerInfo[0].value = data.firstName;
    updateCustomerInfo[1].value = data.lastName;
    updateCustomerInfo[2].value = data.addresses[0].address1;
    updateCustomerInfo[3].value = data.addresses[0].zip;
    updateCustomerInfo[4].value = data.addresses[0].city;
    updateCustomerInfo[5].value = data.addresses[0].country;
    updateCustomerInfo[6].value = data.note;

    setPreviousCustomer({
      id: data.id,
      email: data.email,
      addresses: data.addresses,
    });
    setCustomerInfo(updateCustomerInfo);
  };

  const executeSearch = () => {
    if (customers?.length) {
      const regFirst = new RegExp(searchFirstName, 'gi');
      const regLast = new RegExp(searchLastName, 'gi');
      let customerMatch = customers.flatMap((customer) =>
        customer.node.firstName.match(regFirst) &&
        customer.node.lastName.match(regLast)
          ? [
              {
                firstName: customer.node.firstName,
                lastName: customer.node.lastName,
                addresses: customer.node.addresses,
                note: customer.node.note,
                id: customer.node.id,
                email: customer.node.email,
              },
            ]
          : []
      );
      setSearchResult(customerMatch as CustomerSearchResult[]);
    }
  };

  const handleAddProduct = () => {
    setListItems([...listItems, defaultListItem]);

    setSelectedDate([...selectedDate, defaultDate]);

    setSelectedTime([...selectedTime, defaultTime]);
    setSeletedMetafield([...selectedMetafield, defaultMetafield]);
  };

  const handleRemoveProduct = (index: number) => {
    setListItems(listItems.filter((_, i) => i !== removeIndex));
    setRemoveIndex(undefined);
  };

  const handleCustomerInfo = (index: number, value: string) => {
    const updateCustomerInfo = [...customerInfo];
    updateCustomerInfo[index].value = value;
    setCustomerInfo(updateCustomerInfo);
    setPreviousCustomer(undefined);
  };

  const handleProductInfo = (id: string, index: number) => {
    const productMetafield =
      productOptions.find((item) => item.value === id)?.metafield ?? '';

    const jsonMetafield = JSON.parse(productMetafield);

    const updateListItem = [...listItems];
    updateListItem[index].variantId = id;
    updateListItem[index].eventType = jsonMetafield.eventType;
    updateListItem[index].errors.productError = '';
    setListItems(updateListItem);

    const updateMetafield = [...selectedMetafield];
    updateMetafield[index].metafield = productMetafield;
    setSeletedMetafield(updateMetafield);

    handleDate(index, selectedDate[index].date.getTime());
  };

  const handleSpots = (value: number, index: number) => {
    const updateListItem = [...listItems];
    updateListItem[index].quantity = value;
    updateListItem[index].errors.quantityError = '';
    setListItems(updateListItem);
  };

  const handleTime = (value: string, index: number) => {
    const updateTime = [...selectedTime];
    updateTime[index].time = value;
    setSelectedTime(updateTime);
  };

  const addZero = (i: number) => {
    let result = i.toString();
    if (i < 10) {
      result = '0' + i;
    }
    return result;
  };

  const getFreeSlots = async (slots: any[], index: number, date: number) => {
    const newDate = new Date(date);
    const end = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    end.setHours(23, 59, 59, 0);
    const result: any[] = await getReservedSlots(
      token,
      newDate.getTime() / 1000,
      end.getTime() / 1000
    );

    const meta = JSON.parse(selectedMetafield[index].metafield);
    let reservedInApp = Array<any>();
    let closedDay = false;

    // looppaa kakki exceptionit
    for (let j = 0; j < meta.exceptions.length; j++) {
      const e = meta.exceptions[j];
      // looppaa kaikki päivät exceptionissa
      for (let k = 0; k < e.days.length; k++) {
        // jos varaus päivälle on merkattu poikkeava paikka määrä niin alkuperäinen paikka määrä muutetaan vastaamaan poikkeusta
        if (newDate.getTime() === e.days[k].date) {
          slots.forEach((s, i) => {
            s.freespots = e.participants;
          });
        }
      }
    }

    // tarkistetaan onko valittu päivä merkattu kiinni olevaksi
    for (let j = 0; j < meta.closedDates.length; j++) {
      const e = meta.closedDates[j];
      const newDateStr =
        newDate.getDate() +
        '.' +
        (newDate.getMonth() + 1) +
        '.' +
        newDate.getFullYear();
      if (newDateStr === e) {
        closedDay = true;
      }
    }

    result.forEach((rs, i) => {
      if (rs.event === listItems[index].eventType) {
        const inAppDay = new Date(rs.slot * 1000);
        reservedInApp.push({
          time:
            addZero(inAppDay.getHours()) + ':' + addZero(inAppDay.getMinutes()),
          freespots: rs.bookings,
        });
        slots.forEach((s, j) => {
          const hours = s.time.split(':');
          const day = new Date(date);
          day.setHours(hours[0], hours[1]);
          // vähennetään vapaiden paikkojen määrästä varatut paikat
          if (day.getTime() / 1000 === rs.slot) {
            s.freespots = s.freespots - rs.bookings;
          }
          // poistetaan reservedInApp listasta ne ajat jotka vastaavat tuotteelle merkattuja varattavia aikoja
          reservedInApp = reservedInApp.filter((item) => item.time !== s.time);
        });
      }
    });

    const newListItem = listItems;
    newListItem[index].dayInfo = slots;
    newListItem[index].reservedInApp = reservedInApp;
    newListItem[index].closedDay = closedDay;
    setListItems(newListItem);
  };

  const handleDate = (id: number, date: number) => {
    const updateDate = [...selectedDate];
    const newDay = new Date(date);
    updateDate[id].date = newDay;
    updateDate[id].dateStr =
      newDay.getDate() +
      '.' +
      (newDay.getMonth() + 1) +
      '.' +
      newDay.getFullYear();
    setSelectedDate(updateDate);

    const bookingMetafield = JSON.parse(selectedMetafield[id].metafield);

    // Luodaan lista johon lisätään valitun tuotteen kaikki varaukseen merkatut ajat valitulta päivältä

    let timesArr = [];
    for (let i = 0; i < bookingMetafield.booking.length; i++) {
      const e = bookingMetafield.booking[i];
      if (e.start <= date && e.end >= date) {
        let currentDay = new Date(date);
        let currentWeekDay = currentDay.getDay();
        for (let j = 0; j < e.slots[weekDayNames[currentWeekDay]].length; j++) {
          const el = e.slots[weekDayNames[currentWeekDay]][j];
          timesArr.push({ time: el, freespots: bookingMetafield.freeSpots });
        }
      }
    }

    // Lisää halutuun lineItems objektiin listan avoimista varaus ajoista ja niillä olevat vapaat paikat
    // lisäksi objecktiin lisätään lista varauksista jotka eivät osu avoimille varauksille toisin sanoen
    // ne varaukset jotka on tehty erikseen sovelluksen kautta sellaisille ajoille joita ei ole tuoteille
    // merkattu varattaviksi ajoiksit

    getFreeSlots(timesArr, id, date);

    const updateListItem = [...listItems];
    updateListItem[id].booking = timesArr;
    setListItems(updateListItem);
  };

  const handleCreateBooking = async () => {
    let customerError = false;

    if (!customerError) {
      const updateCustomerError = customerInfo;
      customerInfo.forEach((c, i) => {
        if (c.value === '' && c.name !== 'note') {
          customerError = true;
          updateCustomerError[i].error = c.label + ' is required';
        }
      });
      setCustomerInfo(updateCustomerError);
    }

    if (previousCustomer?.id) {
      customerError = false;
    }

    let orderError = false;

    const updateListItemError = listItems;
    listItems.forEach((li, i) => {
      if (li.variantId === 'vt') {
        orderError = true;
        updateListItemError[i].errors.productError = 'Choose product';
      }
      if (li.quantity < 1) {
        orderError = true;
        updateListItemError[i].errors.quantityError = 'Quantity must over 0';
      }
    });

    setListItems(updateListItemError);

    if (!orderError && !customerError) {
      let customer;

      if (!previousCustomer?.id) {
        customer = {
          acceptsMarketing: false,
          addresses: {
            address1: customerInfo[2].value,
            city: customerInfo[4].value,
            countryCode: customerInfo[5].value,
            zip: customerInfo[3].value,
          },
          firstName: customerInfo[0].value,
          lastName: customerInfo[1].value,
          marketingOptInLevel: null,
          note: customerInfo[6].value,
          tags: '',
        };
      } else {
        customer = {
          id: previousCustomer?.id,
        };
      }

      let products = [];
      for (let i = 0; i < listItems.length; i++) {
        const lineItem = {
          quantity: listItems[i].quantity,
          customAttributes: {
            key: 'booking',
            value: JSON.stringify({
              time: selectedDate[i].dateStr + ' ' + selectedTime[i].time,
              event: listItems[i].eventType,
            }),
          },
          variantId: listItems[i].variantId,
        };
        products.push(lineItem);
      }

      const orderData = { lineItems: products, customer: customer };
      try {
        await createOrder(token, orderData);

        setCustomerInfo(customerFields);
        setListItems([defaultListItem]);
        setSeletedMetafield([defaultMetafield]);
        setSelectedDate([defaultDate]);
        setSelectedTime([defaultTime]);
        setPreviousCustomer(undefined);
        setOrderProcessed(true);
      } catch (error) {
        setError(true);
      }
    }
    setCreate(false);
  };

  return (
    <Page>
      <div style={{ padding: '10px' }}>
        <div>
          <Heading>Customer info</Heading>
        </div>
      </div>
      <div>
        <Layout>
          <Layout.Section oneThird>
            <Card>
              <div style={{ padding: '10px' }}>
                <TextField
                  type="text"
                  value={searchFirstName}
                  label="Firstname"
                  onChange={(value) => setSearchFirstName(value)}
                />
                <TextField
                  type="text"
                  value={searchLastName}
                  label="Lastname"
                  onChange={(value) => setSearchLastName(value)}
                />
                <div style={{ paddingTop: '10px' }}>
                  <Button onClick={() => executeSearch()}>Search</Button>
                </div>
              </div>
              {searchResult?.length ? (
                <Scrollable style={{ maxHeight: '350px' }}>
                  <ResourceList
                    items={searchResult}
                    renderItem={(item) => {
                      const {
                        firstName,
                        lastName,
                        addresses,
                        note,
                        id,
                        email,
                      } = item;
                      const media = <Avatar customer size="medium" />;
                      return (
                        <ResourceItem
                          id={id}
                          media={media}
                          onClick={() => chooseCustomer(item)}
                        >
                          <h3>
                            <TextStyle variation="strong">
                              {firstName} {lastName}
                            </TextStyle>
                          </h3>
                          {addresses.map((a, i) => (
                            <div key={i}>
                              <TextStyle variation="strong">
                                Address:{' '}
                              </TextStyle>
                              {a.address1}
                              <TextStyle variation="strong"> City: </TextStyle>
                              {a.city}
                              <TextStyle variation="strong">
                                {' '}
                                Country:{' '}
                              </TextStyle>
                              {a.country}
                              <TextStyle variation="strong"> Zip: </TextStyle>
                              {a.zip}
                            </div>
                          ))}
                          <div>
                            <TextStyle variation="strong"> Note: </TextStyle>
                            {note}
                          </div>
                          <div>
                            <TextStyle variation="strong"> Email: </TextStyle>
                            {email}
                          </div>
                        </ResourceItem>
                      );
                    }}
                  />
                </Scrollable>
              ) : (
                <h3 style={{ padding: '0 0 10px 10px' }}>
                  <TextStyle variation="strong">No results</TextStyle>
                </h3>
              )}
            </Card>
          </Layout.Section>
          <Layout.Section oneThird>
            <Card>
              <div style={{ padding: '10px' }}>
                {previousCustomer !== undefined && (
                  <div>
                    <Card.Section title="Name">
                      <p>
                        {customerInfo[0].value} {customerInfo[1].value}
                      </p>
                    </Card.Section>
                    <Card.Section title="Addresses">
                      {previousCustomer?.addresses.map((pc) => (
                        <Card.Subsection>
                          {pc.address1}
                          <br />
                          {pc.zip}
                          <br />
                          {pc.city}
                          <br />
                          {pc.country}
                        </Card.Subsection>
                      ))}
                    </Card.Section>
                    <Card.Section title="Note">
                      <Card.Subsection>{customerInfo[6].value}</Card.Subsection>
                    </Card.Section>
                    {previousCustomer.email && (
                      <Card.Section title="Email">
                        <Card.Subsection>
                          {previousCustomer.email}
                          <br />
                          <TextStyle variation="negative">
                            Email notification will be sent to customer about
                            this order
                          </TextStyle>
                        </Card.Subsection>
                      </Card.Section>
                    )}
                    <div style={{ paddingTop: '10px' }}>
                      <Button
                        destructive
                        onClick={() => clearPreviousCustomer()}
                      >
                        Clear fields and make a new customer
                      </Button>
                    </div>
                  </div>
                )}
                {previousCustomer === undefined && (
                  <FormLayout>
                    {customerInfo.map((ci, i) =>
                      ci.label === 'Country' ? (
                        <Select
                          key={ci.name}
                          label="Countries"
                          options={editedCountries}
                          value={ci.value}
                          onChange={(value) => {
                            handleCustomerInfo(i, value);
                          }}
                          error={ci.error}
                        />
                      ) : (
                        <TextField
                          key={ci.name}
                          value={ci.value}
                          type="text"
                          error={ci.error}
                          label={ci.label}
                          onChange={(value) => {
                            handleCustomerInfo(i, value);
                          }}
                        />
                      )
                    )}
                  </FormLayout>
                )}
              </div>
            </Card>
          </Layout.Section>
        </Layout>
      </div>
      <div style={{ padding: '10px' }}>
        <div>
          <Heading>Products</Heading>
        </div>
      </div>
      <Card>
        {listItems.map((LI, i) => (
          <Card.Section key={LI.id}>
            <div style={{ display: 'flex' }}>
              <span style={{ flex: 1 }}>
                <TextStyle variation="strong">Product: {i + 1}</TextStyle>
              </span>
              {removeIndex !== i && (
                <span>
                  <Button
                    icon={DeleteMajor}
                    destructive
                    onClick={() => {
                      setRemoveIndex(i);
                    }}
                  ></Button>
                </span>
              )}
              {removeIndex === i && (
                <span>
                  <span style={{ marginRight: '.4rem' }}>Really remove?</span>
                  <Button destructive onClick={() => handleRemoveProduct(i)}>
                    Yes
                  </Button>
                  <Button
                    onClick={() => {
                      setRemoveIndex(undefined);
                    }}
                  >
                    Cancel
                  </Button>
                </span>
              )}
            </div>
            <FormLayout>
              <div style={{ padding: '0 10px 10px 10px' }}>
                <FormLayout.Group condensed>
                  <Select
                    key={i}
                    label="Products"
                    options={productOptions}
                    value={listItems[i].variantId}
                    onChange={(val) => {
                      handleProductInfo(val, i);
                    }}
                    error={listItems[i].errors.productError}
                  />
                  <TextField
                    label="Event spots"
                    value={listItems[i].quantity.toString()}
                    onChange={(value) => handleSpots(parseInt(value), i)}
                    type="number"
                    error={listItems[i].errors.quantityError}
                  />
                  <TextField
                    label="Time"
                    value={selectedTime[i].time}
                    onChange={(value) => handleTime(value, i)}
                    type="time"
                  />
                  <DatePicker
                    label="Choose day"
                    date={selectedDate[i].date.getTime()}
                    onClear={() => handleDate(i, 0)}
                    onChange={(date: number) => handleDate(i, date)}
                  />
                </FormLayout.Group>
              </div>
            </FormLayout>
            <div style={{ padding: '10px 0px' }}>
              {LI.closedDay === true && (
                <TextStyle variation="strong">
                  Day has been marked as closed
                </TextStyle>
              )}
              {LI.closedDay === false && (
                <div style={{ paddingBottom: '20px' }}>
                  {LI.dayInfo.length > 0 && (
                    <TextStyle variation="strong">
                      Available bookig slots
                    </TextStyle>
                  )}
                  {LI.dayInfo.map((d, i) => (
                    <p>
                      {d.time}: Open slots: {d.freespots}
                    </p>
                  ))}
                </div>
              )}
              {LI.reservedInApp.length > 0 && (
                <TextStyle variation="strong">
                  Bookings made outside of booking slots
                </TextStyle>
              )}
              {LI.reservedInApp.map((RA, i) => (
                <p>
                  {RA.time}: Reserved slots: {RA.freespots}
                </p>
              ))}
            </div>
          </Card.Section>
        ))}
        <Card.Section>
          <span style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <Button onClick={handleAddProduct}>Add product</Button>
          </span>
        </Card.Section>
      </Card>
      <div
        style={{
          padding: '20px',
          display: 'flex',
          flexDirection: 'row-reverse',
        }}
      >
        {create !== true && (
          <span>
            <Button
              primary
              onClick={() => {
                setCreate(true);
              }}
            >
              Create Order
            </Button>
          </span>
        )}
        {create === true && (
          <span>
            <span style={{ marginRight: '.4rem' }}>Create order?</span>
            <Button primary onClick={handleCreateBooking}>
              Yes
            </Button>
            <Button
              onClick={() => {
                setCreate(false);
              }}
            >
              Cancel
            </Button>
          </span>
        )}
        {orderProcessed && (
          <Toast
            content="Order created"
            onDismiss={() => setOrderProcessed(false)}
          />
        )}
        {error && (
          <Toast
            content="Something went wrong. Please try reloading the page and check information"
            error
            onDismiss={() => setError(false)}
            duration={10000}
          />
        )}
      </div>
    </Page>
  );
};

export default Neworder;
