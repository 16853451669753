import { TextStyle, Button, DatePicker as DP, Popover } from '@shopify/polaris';
import { CalendarMinor } from '@shopify/polaris-icons';
import { useEffect, useState } from 'react';
type DateProps = {
  pickerStyle?: string;
  label?: string;
  date?: number;
  onChange: (d: number) => void;
  onClear?: () => void;
};
const DatePicker = (props: DateProps) => {
  const style = props.pickerStyle;
  const label = props.label;
  const { date, onChange } = props;
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [curr, setCurr] = useState<Date>(new Date());

  useEffect(() => {
    if (date) setCurr(new Date(date));
  }, [date]);

  const toDate = (d?: number) => {
    const date = new Date(d || Date.now());
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
  };

  const togglePopoverActive = () => {
    setDatePickerOpen((datePickerOpen) => !datePickerOpen);
  };

  let customStyle: object;
  let hideLine: object;
  switch (style) {
    case 'exception':
      customStyle = { display: 'flex', flexFlow: 'column' };
      hideLine = { display: 'none' };
      break;
    case 'booking':
      customStyle = { display: 'flex', width: '100%', flexFlow: 'column' };
      hideLine = { marginRight: '.4rem', minWidth: 64 };
      break;
    case 'orderForm':
      customStyle = { display: 'flex', width: '100%', flexFlow: 'column' };
      hideLine = { marginRight: '.4rem', minWidth: 64 };
      break;
    default:
      customStyle = { display: 'flex', width: '100%', flexFlow: 'column' };
      hideLine = { marginRight: '.4rem', minWidth: 64 };
      break;
  }

  return (
    <div style={customStyle}>
      <label>{label}</label>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={hideLine}>
          <TextStyle variation="strong">{date ? toDate(date) : '-'}</TextStyle>
        </span>
        <div>
          <Popover
            active={datePickerOpen}
            activator={
              <Button
                icon={CalendarMinor}
                onClick={() => setDatePickerOpen(true)}
              ></Button>
            }
            onClose={togglePopoverActive}
          >
            {datePickerOpen && (
              <div
                style={{
                  padding: '10px',
                  background: '#fff',
                  width: '100%',
                  maxWidth: 320,
                  minWidth: 240,
                  zIndex: 1,
                }}
              >
                <DP
                  month={curr.getMonth()}
                  year={curr.getFullYear()}
                  onMonthChange={(month, year) =>
                    setCurr(new Date(year, month))
                  }
                  onChange={({ start }) => setCurr(start)}
                />
                <div
                  style={{
                    paddingTop: '10px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    primary
                    onClick={() => {
                      onChange(curr ? curr.getTime() : date!);
                      setDatePickerOpen(false);
                      setCurr(new Date());
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    destructive
                    onClick={() => {
                      setDatePickerOpen(false);
                      setCurr(new Date());
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            )}
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default DatePicker;
