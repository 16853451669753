import { useCallback, useEffect, useState } from 'react';
import { authenticate, install } from '../api';
import useQueryParams from './useQueryParams';

let loading = false;

function useAuth() {
  const [token, setToken] = useState('');
  const [shop, setShop] = useState('');
  const [error, setError] = useState(false);
  //const [loading, setLoading] = useState(true);
  const queryParams = useQueryParams();
  const { code } = queryParams;

  const auth = useCallback(async () => {
    try {
      const data = await authenticate(queryParams);
      if (data.token) {
        setToken(data.token);
        setShop(data.shop);
      }
    } catch (e) {
      console.error(e);
      setError(true);
    } finally {
      loading = false;
    }
  }, [queryParams]);

  const initInstall = useCallback(async () => {
    try {
      await install(queryParams);
    } catch (e) {
      console.error(e);
      setError(true);
    } finally {
      loading = false;
    }
  }, [queryParams]);

  useEffect(() => {
    if (!code && !token && !loading) {
      loading = true;
      auth();
    } else if (code && !token && !loading) {
      loading = true;
      initInstall();
    }
  }, [code, auth, token, initInstall, shop]);

  return { token, shop, error };
}

export default useAuth;
